//import Vue from 'vue'
//import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n'

//Vue.use(VueI18n)
function loadLocaleMessages() {
	const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
	const messages = {}
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			messages[locale] = locales(key)
		}
	})
	return messages
}

const numberFormats = {
	'en-US': {
		currency: {
			style: 'currency',
			currency: 'USD'
		}
	},
	'ru-RU': {
		currency: {
			style: 'currency',
			currency: 'RUB',
			currencyDisplay: 'symbol'
		}
	}
}

const dateTimeFormats = {
	'en-US': {
		short: {
			year: 'numeric',
			month: 'short',
			day: 'numeric'
		},
		long: {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			weekday: 'long',
			hour: 'numeric',
			minute: 'numeric'
		}
	},
	'ru-RU': {
		short: {
			year: 'numeric',
			month: 'short',
			day: 'numeric'
		},
		long: {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			weekday: 'long',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true
		}
	}
}

export default createI18n({
	locale: localStorage.getItem('locale') || 'ru',
	fallbackLocale: 'en',
	messages: loadLocaleMessages(),
	dateTimeFormats: dateTimeFormats,
	numberFormats: numberFormats
})
