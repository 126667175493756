<template>
	<section class="hero is-fullheight" style="padding-bottom: 100px;">

		<locale-changer />

		<div class="hero-body">
			<div class="backdrop" v-if="backdrop">
				<img src="https://thumb.tildacdn.com/tild6633-3561-4439-b130-346132363465/-/resize/730x/-/format/webp/photo.png" />
			</div>
			<div class="container has-text-centered">
				
				<router-view/>

			</div>
		</div>

	</section>
</template>

<script>
import LocaleChanger from './views/LocaleChanger'

export default {
	components: {
		LocaleChanger
	},
	data(){
		return {
			backdrop: process.env.VUE_APP_SHOW_BACKDROP == "true"
		}
	},
	mounted(){
		//console.log('process.env', process.env)
	}
}
</script>

<style>
@font-face {
	font-family: "TT-Prosto-Sans";
	src: url("https://static.tildacdn.com/tild3937-3662-4335-b531-626430306266/TT-Prosto-Sans-Regul.woff") format("woff");
}

html{
	background: #000 !important;
}

body {
	font-family: 'TT-Prosto-Sans',Arial,sans-serif !important;
}
.hero {
	background-color: #180b4f;
}
.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	opacity: 0.15;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.container p,
.container h1,
.container h3{
	color: #333 !important;
}
.container h1{
	font-size: 60px;
}
.container p{
	font-size: 20px;
}

.hero-body .container p,
.hero-body .container h1,
.hero-body .container h3,
.hero-body .container h4{
	color: #fff !important;
}

</style>