<template>
	<div>
		<template v-if="isLoaded">
			<div class="container has-text-centered" v-if="active_stages.length == 0">
				<h1 class="title">
					{{ $t('nothing_is_here') }}
				</h1>
				<div v-html="$t('main_system_description')"></div>
			</div>
			<div class="container has-text-centered" v-else>
				<h1 class="title is-1">
					{{ $t('piterkit') }}
				</h1>
				<h3 class="subtitle is-4">
					{{ $t('welcome.to.festival') }}
				</h3>
				
				<p>
					{{ $t('choose.stage') }}
				</p>

				<div class="mt-5 has-text-left">
					<div class="active-stage" v-for="stage in active_stages" :key="'stage'+stage.id">
						<router-link tag="h4" class="title is-4 has-text-white stage-title" :to="'/stage/'+stage.id+'/foyer'">
							{{ stage.title }}
						</router-link>
						<div class="stage-description has-text-left mt-3" v-html="stage.description"></div>
					</div>
				</div>

			</div>
		</template>
	</div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'

export default {
	name: 'Home',
	components: {
	},
	data(){
		return {
			active_stages: [],
			isLoaded: false
		}
	},
	mounted(){
		this.getActiveStages()
	},
	methods: {
		async getActiveStages(){
			await axios.get(`${process.env.VUE_APP_API_LOCATION}/api/active_stages`).then(r => {
				this.active_stages = r.data.stages
				this.isLoaded = true
			})
		}
	}
}
</script>

<style>
.active-stage{
	max-width: 315px;
	width: 100%;
    padding: 20px;
    border: 1px solid #fff;
    border-radius: 10px;
    margin: 0 auto 10px auto;
}
.active-stage * {
	color: #fff;
}
.active-stage .stage-description *,
.active-stage .stage-description p{
	color: #afafaf !important;
}
.active-stage .stage-description p{
	font-size: 0.9em;
}
</style>