import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import i18n from './i18n'
import Vue3Geolocation from 'vue3-geolocation';

import 'bulma/css/bulma.css'

const app = createApp(App)
app.use(router)
app.use(i18n)
app.use(Vue3Geolocation)
app.mount('#app')

import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'app_key',
    cluster: 'mt1',
    wsHost: process.env.VUE_APP_API_DOMAIN,
    wsPort: 6002,
    wssPort: 6002,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss']
});