<template>
	<div class="locale-changer p-3">
		<button class="button is-small is-light is-outlined" @click="switchLocale(activate_locale.slug)">
			{{ activate_locale.title }}
		</button>
	</div>
</template>

<script>
import _ from 'lodash'

export default {
	name: 'locale-changer',
	data () {
		return { 
			langs: [
				{ slug: 'ru', title: 'Русский' },
				{ slug: 'en', title: 'English' },
			],
//			open: false
		}
	},
	computed: {
/*		current_locale(){
			return _.find(this.langs, { slug: this.$i18n.locale })
		},*/
		activate_locale(){
			return _.find(this.langs, lang => { 
				return lang.slug != this.$i18n.locale
			})
		}
	},
	methods: {
		switchLocale(locale){
			this.$i18n.locale = locale
			localStorage.setItem('locale', locale)
//			this.open = !this.open
		}
	}
}
</script>

<style>
.locale-changer {
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 10000
}
</style>