import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

// offline ticket - /t/:ticket
// electronic ticket - /v/:ticket - stands for viewer
// virtual ticket - /go/ - stands for virtual ticket voting

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'Система онлайн-голосования'
		}
	},
	{
		// just for viewing in player
		path: '/v/:ticket',
		name: 'TicketOnlineView',
		props: true,
		component: () => import(/* webpackChunkName: "ticket_view" */ '../views/TicketOnlineView.vue'),
		meta: {
			title: 'Просмотр программы'
		}
	},
	{
		// for voting
		path: '/t/:ticket',
		name: 'Ticket',
		props: true,
		component: () => import(/* webpackChunkName: "ticket" */ '../views/Ticket.vue'),
		meta: {
			title: 'Голосование'
		}
	},
	{
		// for virtual voting system
		path: '/go/:movie_session_id',
		name: 'VirtualTicket',
		props: true,
		component: () => import(/* webpackChunkName: "virtual_ticket" */ '../views/VirtualTicket.vue'),
		meta: {
			title: 'Голосование'
		}
	},
	{
		path: '/stage/:stage/results/:backdrop?',
		name: 'StageResults',
		props: true,
		component: () => import(/* webpackChunkName: "results" */ '../views/Results.vue'),
		meta: {
			title: 'Результаты голосования'
		}
	},
	{
		path: '/stage/:stage/foyer',
		name: 'StageFoyer',
		props: true,
		component: () => import(/* webpackChunkName: "stage_foyer" */ '../views/StageFoyer.vue'),
		meta: {
			title: 'Фойе мероприятия'
		}
	},
	{
		path: '/session/:movie_session_id/foyer',
		name: 'SessionFoyer',
		props: true,
		component: () => import(/* webpackChunkName: "session_foyer" */ '../views/SessionFoyer.vue'),
		meta: {
			title: 'Регистрация на показ'
		}
	},
	{
		path: '/session/:movie_session_id/idbeholdl',
		name: 'SessionFoyerTest',
		props: true,
		component: () => import(/* webpackChunkName: "session_foyer_test" */ '../views/SessionFoyerTest.vue'),
		meta: {
			title: 'Тест плеера показ'
		}
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((toRoute, fromRoute, next) => {
	window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Система онлайн-голосования';
	
	next();
})

export default router
